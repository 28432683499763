
import Vue from "vue";
import { BANCA_CONTEXT, GET_BANCA } from "./store/banca/banca.actions";

export default Vue.extend({
  name: "App",
  methods: {
    aoConsultarBanca() {
      this.$store.dispatch(`${BANCA_CONTEXT}/${GET_BANCA}`)
    }
  }
});
