import Vue from 'vue'
import Vuex from 'vuex'
import apiHttpService, { httpClient } from '@/core/http/api.http.service';
Vue.use(Vuex)


//modules
import banca from './banca/banca';
import { keyCompanyName, limparStorage } from '@/core/utils/Auth';

const captchaSiteKey = process.env.VUE_APP_CAPTCHA_PUBLIC_KEY;
const token = localStorage.getItem(`${keyCompanyName}token`)
const codigo = localStorage.getItem(`${keyCompanyName}codigo`)
const nome = localStorage.getItem(`${keyCompanyName}nome`)
const ehPrimeiroAcesso = localStorage.getItem(`${keyCompanyName}ehPrimeiroAcesso`)
const cpf = localStorage.getItem(`${keyCompanyName}cpf`)
const logoUsuario = localStorage.getItem(`${keyCompanyName}logo`)

//CONSTS
export const GET_INFORMACAO_USUARIO_LOGADO_REQUEST = 'GET_INFORMACAO_USUARIO_LOGADO_REQUEST'

export default new Vuex.Store({
  state: {
    usuarioLogado: {
      codigo: codigo ? codigo : null,
      token: token ? token : '',
      nome: nome,
      ehPrimeiroAcesso: ehPrimeiroAcesso,
      cpf: cpf,
      logo: logoUsuario ? logoUsuario : require('@/assets/logo-empty.jpg'),
      premium: false
    }
  },
  getters: {
    captchaSiteKey: () => captchaSiteKey,
    useCaptcha: () => true,
    nomeUsuarioLogado: state => state.usuarioLogado.nome,
    cpfUsuarioLogado: state => state.usuarioLogado.cpf,
    usuarioEstalogado: state => !!state.usuarioLogado.token,
    token: state => state.usuarioLogado.token,
    logo: state => state.usuarioLogado.logo,
    isPremium: (state): boolean => state.usuarioLogado.premium,
  },
  actions: {
    [GET_INFORMACAO_USUARIO_LOGADO_REQUEST]: ({ state, commit, dispatch }: any, payload: any) => {
      return new Promise((resolve, reject) => {
        apiHttpService.get<any>(`/usuario/premium`).then(result => {
          if (result.success)
            commit('USUARIO_PREMIUM', result.data);
        }).finally(() => {
          resolve(true);
        })
      })
    },
    ['AUTH_LOGOUT_REQUEST']: ({ state, commit, dispatch }: any, payload: any) => {
      return new Promise((resolve, reject) => {
        commit('AUTH_LOGOUT');
        resolve(true);
      })
    },
    ['AUTH_REQUEST']: ({ commit, dispatch }: any, payload: any) => {
      return new Promise((resolve, reject) => {
        apiHttpService.post<any>(`/authorization`, payload, {
          headers: {
            'Authorizarion': 'Bearer'
          }
        }).then(result => {
          if (result.success) {
            httpClient.defaults.headers.common['Authorization'] = httpClient.defaults.headers.common['Authorization'] = `bearer ${result.data.token}`;
            commit('AUTH_SUCCESS', result.data)
          }
          resolve(result);
        }).catch(err => {
          reject(err)
        });
      });
    }
  },
  mutations: {
    ['USUARIO_PREMIUM']: (state: any, payload: any) => {
      state.usuarioLogado.premium = payload.premium;
    },
    ['UPLOAD-LOGO']: (state: any, payload: any) => {
      state.usuarioLogado.logo = payload.url;
      localStorage.setItem(`${keyCompanyName}logo`, payload.url)
    },
    ['AUTH_SUCCESS']: (state: any, payload: any) => {
      state.usuarioLogado = payload;
      localStorage.setItem(`${keyCompanyName}codigo`, payload.codigo)
      localStorage.setItem(`${keyCompanyName}token`, payload.token)
      localStorage.setItem(`${keyCompanyName}nome`, payload.nome)
      localStorage.setItem(`${keyCompanyName}ehPrimeiroAcesso`, payload.ehPrimeiroAcesso)
      localStorage.setItem(`${keyCompanyName}cpf`, payload.cpf)
      const vm = Vue as any;
      const $cookie = vm.$cookies;
      $cookie.set('betsoft-token', payload.token, "7d");

      if (payload.logo)
        localStorage.setItem(`${keyCompanyName}logo`, payload.logo)
    },
    ['AUTH_LOGOUT']: (state: any, payload: any) => {
      const vm = Vue as any;
      const $cookie = vm.$cookies;

      const cookieKey = 'betsoft-token';
      $cookie.remove(cookieKey);

      state.usuarioLogado = {
        codigo: null,
        token: '',
        nome: '',
        ehPrimeiroAcesso: false,
        cpf: '',
        logo: ''
      }

      limparStorage();
    },
  },
  modules: {
    banca
  }
})
