import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store/index';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/plataforma',
    name: 'home',
    redirect: 'minhas-bancas',
    component: () => import('@/views/plataforma/IndexView.vue'),
    children: [{
      path: 'minhas-bancas',
      name: 'MinhasBancas',
      component: () => import('@/views/plataforma/banca/IndexView.vue'),
      children: [
        {
          path: '',
          name: 'ListarBancas',
          component: () => import('@/views/plataforma/banca/BancaView.vue'),
        },
        {
          path: 'gestao/:codBanca',
          name: 'MinhaBanca',
          component: () => import('@/views/plataforma/banca/gestao/IndexView.vue'),
          children: [
            {
              path: '',
              name: 'GestaoView',
              component: () => import('@/views/plataforma/banca/gestao/template/GestaoView.vue'),
            },
            {
              path: 'editar',
              name: 'GestaoEditView',
              component: () => import('@/views/plataforma/banca/BancaEditView.vue'),
            }
          ]
        },
      ],
      meta: {
        auth: true
      },
    },
    {
      path: 'configuracao',
      name: 'configuracao',
      component: () => import('@/views/plataforma/configuracao/IndexView.vue'),
      children: [
        {
          name: 'ConfiguracaoContainer',
          path: '',
          component: () => import('@/views/plataforma/configuracao/ContainerView.vue'),
        },
        {
          name: 'Tipster',
          path: 'tipster',
          component: () => import('@/views/plataforma/configuracao/tipster/IndexView.vue'),
        },
        {
          name: 'CasaAposta',
          path: 'casa-aposta',
          component: () => import('@/views/plataforma/configuracao/casa-aposta/IndexView.vue'),
        },
        {
          name: 'Mercado',
          path: 'mercados',
          component: () => import('@/views/plataforma/configuracao/mercado/IndexView.vue'),
        },
        {
          name: 'Campeonato',
          path: 'campeonato',
          component: () => import('@/views/plataforma/configuracao/campeonato/IndexView.vue'),
        },
      ],
    },
    {
      path: 'sugestao',
      component: () => import('@/views/plataforma/sugestao/IndexView.vue'),
      meta: {
        auth: true
      },
    },
    {
      path: 'relatorio',
      component: () => import('@/views/plataforma/relatorio/IndexView.vue'),
      meta: {
        auth: true
      },
    },
    {
      path: 'meus-dados',
      component: () => import('@/views/plataforma/meus-dados/IndexView.vue'),
      children: [
        {
          name: 'DadosBasico',
          path: '',
          component: () => import('@/views/plataforma/meus-dados/DadosBasicoView.vue'),
        },
        {
          name: 'PlanoCobranca',
          path: 'plano-cobranca',
          component: () => import('@/views/plataforma/meus-dados/PlanoCobrancaView.vue'),
        },
      ],
      meta: {
        auth: true
      },
    },
    {
      path: 'premium',
      component: () => import('@/views/plataforma/premium/IndexView.vue'),
      meta: {
        auth: true
      },
    },
    {
      path: 'planos',
      component: () => import('@/views/plataforma/planos/IndexView.vue'),
      meta: {
        auth: true
      },
    },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/plataforma/login',
      component: () => import('@/layouts/LoginLayout.vue'),
      meta: {
        auth: false
      },
      children: [
        {
          path: '',
          name: 'login',
          component: () => import('@/views/plataforma/login/LoginView.vue')
        },
        {
          path: 'cadastro',
          name: 'cadastro',
          component: () => import('@/views/plataforma/login/cadastro/IndexView.vue')
        },
      ],
    },
    {
      path: '/plataforma/banca/:codBanca',
      component: () => import('@/layouts/ExternoLayout.vue'),
      meta: {
        auth: false
      },
      children: [
        {
          path: '',
          name: 'MinhaBancaExternoView',
          component:() => import(`@/views/plataforma/banca/externo/BancaView.vue`),
        },
      ],
    },
    {
      path: '',
      redirect: '/institucional',
      meta: {
        auth: false
      }
    },
    {
      path: '/institucional',
      component: () => import('@/layouts/SiteLayout.vue'),
      children: [
        {
          path: '',
          name: 'Site',
          component: () => import('@/views/site/SiteView.vue')
        },
      ],
      meta: {
        auth: false
      }
    },
    {
      path: '/plataforma',
      component: () => import('@/layouts/AppLayout.vue'),
      children: routes,
      meta: {
        auth: true
      }
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound',  component: () => import('@/views/nao-encontrado/IndexView.vue') },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (store.getters.usuarioEstalogado) {
      next()
      return
    }
    next('/plataforma/login')
  } else {
    next()
  }
})

export default router
