import axios from 'axios';
 import { IPaging } from '@/core/models/paging.interface';
import { IFormResult, IPageResult, IResult } from '@/core/models/results.interface';
import { normalizeUrlWithParameters } from '@/core/utils/utils';

export const httpClient = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? '' : `${process.env.VUE_APP_API_URL}`
});


class ApiHttpService {
    // getWithPaging<TModel>(resource: string, paging?: any, filter?: any) {
    //     const urlApi = normalizeUrlWithParameters(
    //         `/api${resource}`,
    //         paging,
    //         filter
    //     );

    //     return new Promise<any<TModel>>((resolve, reject) => {
    //         httpClient.get<any<TModel>>(urlApi)
    //             .then(({ data }) => resolve(data))
    //             .catch(error => reject(error));
    //     });
    // }

    get<TModel>(resource: string, headers?: any) {
        const baseUrlApi = `/api${resource}`;
        return new Promise<IResult<TModel>>((resolve, reject) => {
            httpClient.get<IResult<TModel>>(baseUrlApi, {
                headers: headers
            })
                .then(({ data }) => resolve(data))
                .catch(error => reject(error));
        });
    }


    post<TModel>(resource: string, body?: any, headers?: any) {
        const baseUrlApi = `/api${resource}`;
        return new Promise<IFormResult<TModel>>((resolve, reject) => {
            httpClient.post<IFormResult<TModel>>(baseUrlApi, body, {
                headers: headers
            }).then(({ data }) => resolve(data)).catch(error => reject(error));
        });
    }

    // download(resource: string, fileName?: string) {
    //     const baseUrlApi = `/api${resource}`;
    //     return new Promise<boolean>((resolve, reject) => {
    //         httpClient.get(baseUrlApi, { responseType: "blob", })
    //             .then(({ data, status }) => {
    //                 if (status === 204) {
    //                     resolve(false);
    //                 } else {
    //                     const file = new Blob([data], { type: data.type });
    //                     const fileURL = URL.createObjectURL(file);
    //                     const a = document.createElement("a");
    //                     document.body.appendChild(a);
    //                     a.href = fileURL;
    //                     a.download = `${fileName}`;
    //                     a.click();
    //                     setTimeout(() => {
    //                         window.URL.revokeObjectURL(fileURL);
    //                         document.body.removeChild(a);
    //                     }, 0);

    //                 }
    //                 resolve(true);
    //             }).catch(error => reject(error));
    //     });
    // }
}

export default new ApiHttpService();