import { GET_BANCA } from "./banca.actions"
import httpClientService from "@/core/http/api.http.service";

export default {
    namespaced: true,
    state: {
        banca: {
            valorFormatado: ''
        },
        unidades: []
    },
    getters: {
        valorAtual: (state: any) => state.banca.valorFormatado,
        valorInicial: (state: any) => state.banca.valorInicialFormatado,
    },
    actions: {
        [GET_BANCA]: ({ commit }: any, payload: any) => {
            return new Promise((resolve, reject) => {
                const url = `/banca`
                httpClientService.get<any>(url).then((result) => {
                    commit("STATE", result);
                    resolve(result)
                });
            });
        },
    },
    mutations: {
        ['STATE']: (state: any, { data }: any) => {
          state.banca = data.banca;
          state.unidades = data.unidades;
        },
        ['aoDebitarCreditarBanca']: (state: any, data: any) => {
            state.banca.valor = data.saldo;
            state.banca.valorFormatado = data.saldoFormatado;
          },
    }
}