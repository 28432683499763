import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import authToken from '@/plugins/auth';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib';

//Interceptors
import '@/core/interceptors/auth.interceptor'

Vue.config.productionTip = false

import Toast from "vue-toastification";

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import device from "vue-device-detector"
Vue.use(device)

Vue.use(Toast, {
  pauseOnHover: false, 
  position: 'bottom-right'
});

//Auth
Vue.use(authToken)


Vue.component('v-text-field', <any>VTextField)

//Components
Vue.component('app-captcha', () => import("@/components/shared/captcha/IndexView.vue"));
Vue.component('v-input-currency', () => import("@/components/shared/currency/IndexView.vue"))
Vue.component('v-input-date-picker', () => import("@/components/shared/datepicker/DatePicker.vue"));
Vue.component('v-input-date-picker-field', () => import("@/components/shared/datepicker/DatePickerField.vue"));

//Currency Mask
Vue.use(VCurrencyField, {
  locale: "pt-BR",
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});

import VueMask from 'v-mask'
Vue.use(VueMask);

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apex-chart', VueApexCharts)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies, { expires: '7d' })

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
